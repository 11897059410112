<template>
  <div class="game-creators position-relative">
    <div v-if="items.length" class="mb-0">
      <div class="row">
        <div v-for="item in items" :key="item._id" class="col-md-3 my-2">
          <div class="cre-item border-warning rounded text-center py-3" @click="toggleCreator(item)">
            {{item.name}}
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!items.length" class="alert alert-warning mb-0 border-warning">
      ไม่มีข้อมูล
    </div>
  </div>
</template>
<script>
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'

export default {
  name: 'GameCreators',
  props: ['items'],
  data() {
    return {
      data: null
    }
  },
  methods: {
    toggleCreator(item) {
      this.$emit('toggle', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.game-creators {
  .cre-item {
    cursor: pointer;
  }

  .cre-item:hover {
    color: #FFFFFF;
    background-color: #FDA005;
  }
}
</style>
