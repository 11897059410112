<template>
  <div class="game-items pt-2">

    <nav aria-label="breadcrumb">
      <ul class="breadcrumb py-1">
        <li class="breadcrumb-item"><span class="text-primary cat-link" @click="$emit('back')">{{ categoryName }}</span></li>
        <li class="breadcrumb-item active" aria-current="page">{{ creatorName }}</li>
      </ul>
    </nav>

    <div class="row no-gutters">
      <div v-for="item in items" :key="item._id" class="col-4 col-md-2">
        <div class="game-item text-center">
          <router-link @click.native="goUrl(item._id)" :to="{name: 'PlayGame', params: { gameId: item._id}}" target="_blank">
            <img class="game-img" :src="item.img">
            <br /><span class="game-title">{{ item.name }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="!isLoading && !items.length">ไม่มีรายการเกม</div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="60"
      :width="60"
      color="#CC3636"
      loader="dots"
    />
  </div>
</template>
<script>
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'
import Swal from 'sweetalert2'

export default {
  name: 'GameList',
  props: ['category', 'creator'],
  data() {
    return {
      isLoading: false,
      data: null,
      gameWindow: null,
      interval: null
    }
  },
  computed: {
    UserInfo() {
      return this.$store.state.userProfile
    },
    username() {
      return this.UserInfo?.username
    },
    creatorId() {
      return this.creator?._id ?? null
    },
    categoryName() {
      return this.category?.name ?? null
    },
    creatorName() {
      return this.creator?.name ?? null
    },
    items() {
      if(!this.data)
        return []

      return this.data
    }
  },
  methods: {
    getGames() {
      this.isLoading = true
      GameService.getGames(this.creatorId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message || ( response?.data ? JSON.stringify(response.data) : null ))
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    play(item) {

      if(this.gameWindow) {
        this.gameWindow.close()
      }

      this.isLoading = true
      GameService.playGame(item._id)
      .then((response)=>{
        if(response.success) {
          this.gameWindow = window.open(response.data, 'PlayPopup', 'popup')
          this.checkPopup(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    checkPopup(url) {
      try {
        this.gameWindow.focus()

        clearInterval(this.interval)
        this.interval = setInterval(()=>{
          if (this.gameWindow.closed) {
            clearInterval(this.interval)
            this.$store.dispatch('reloadGameCredit')
          }
        }, 1000)
      }
      catch(e) {
        window.location.href = url
      }
    },
    goUrl(gameId) {
      this.gameWindow = window.open('/play-game/' + gameId, 'PlayPopup')
      try {
        this.gameWindow.focus()
        clearInterval(this.interval)
        this.interval = setInterval(()=>{
          if (this.gameWindow.closed) {
            clearInterval(this.interval)
            this.$store.dispatch('reloadGameCredit')
          }
        }, 1000)
        event.preventDefault()
      }
      catch(e) {}
    }
  },
  mounted() {
    this.getGames()
  },
  beforeDestroy() {
    clearInterval(this.interval)
    if(this.gameWindow) {
      this.gameWindow.close()
    }
  }
}
</script>
<style lang="scss" scoped>
.game-items {

  .cat-link {
    cursor: pointer;
  }

  .cat-link:hover {
    text-decoration: underline;
  }

  .game-item {
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #FFFFFF;

    .game-img {
      max-width: 100%;
    }

    .game-title {
      font-size: 90%;
    }

    a {
      text-decoration: none;
      color: #6c757d;
    }
  }

  .game-item:hover {
    background-color: #FFE1A1;
    border: 1px solid #E5881E;
  }
}
</style>
