<template>
  <div class="game-cats">

    <ul v-if="catItems.length" class="nav nav-tabs">
      <li v-for="item in catItems" :key="item._id" class="nav-item">
        <div class="cat-item nav-link" :class="[{'active text-primary': item._id===category._id}]" @click="toggleCategory(item)">{{item.name}}</div>
      </li>
    </ul>

    <div v-if="!isLoading && !catItems.length">ไม่มีรายการเกม</div>
    <div v-else>
      <GameList v-if="page==='games'" class="mt-2" :category="category" :creator="creator" @back="page='creators'" />
      <GameCreators v-if="page==='creators'" class="mt-2" :items="creatorItems" @toggle="setCreator" />
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="60"
      :width="60"
      color="#CC3636"
      loader="dots"
    />
  </div>
</template>
<script>
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'
import Swal from 'sweetalert2'

import GameList from './components/GameList'
import GameCreators from './components/GameCreators'

export default {
  name: 'GameCategories',
  components: {
    GameList,
    GameCreators
  },
  data() {
    return {
      isLoading: false,
      dataCategories: null,
      category: null,
      dataCreators: null,
      creator: null,
      page: 'creators'
    }
  },
  computed: {
    UserInfo() {
      return this.$store.state.userProfile
    },
    username() {
      return this.UserInfo?.username
    },
    catItems() {
      if(!this.dataCategories)
        return []

      return this.dataCategories
    },
    catId() {
      return this.category?._id ?? null
    },
    creatorItems() {
      if(!this.dataCreators)
        return []

      return this.dataCreators
    }
  },
  watch: {
    catId() {
      if(this.catId) {
        this.getCreators()
      }
    }
  },
  methods: {
    getCategories() {
      this.isLoading = true
      GameService.getCategories()
      .then((response)=>{
        if(response.success) {
          this.dataCategories = response.data

          if(!this.category && this.dataCategories.length) {
            this.category = this.dataCategories[0]
          }
        }else{
          throw new Error(response?.message || ( response?.data ? JSON.stringify(response.data) : null ))
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    getCreators() {
      if(!this.catId)
        return

      this.isLoading = true
      GameService.getCreators(this.catId)
      .then((response)=>{
        if(response.success) {
          this.dataCreators = response.data
        }else{
          throw new Error(response?.message || ( response?.data ? JSON.stringify(response.data) : null ))
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    toggleCategory(item) {
      this.category = item
      this.page = 'creators'
    },
    setCreator(creator) {
      this.creator = creator
      this.page = 'games'
    }
  },
  mounted() {
    this.getCategories()
  }
}
</script>
<style lang="scss" scoped>
.game-cats {
  padding: 15px;

  .cat-item {
    cursor: pointer;

    &.active {
      font-weight: bold;
    }
  }
}
</style>
